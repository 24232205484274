<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="500px">
    <AccountStatus
      @nowApplication="nowApplication"
      @cancelApplication="cancelApplication"
      @againApplication="againApplication"
      @createCompaniesSuccessful="createCompaniesSuccessful"
    ></AccountStatus>
  </el-dialog>
</template>

<script>
import AccountStatus from "@/components/AccountStatus";

export default {
  name: "AccountStatusDialog",
  components: {
    AccountStatus
  },
  data() {
    return {
      desc: "账号状态弹窗",
      dialogVisible: false
    };
  },
  mounted() {
    this.$bus.$on("showAccountStatusDialog", this.showAccountStatusDialog);
  },
  methods: {
    showAccountStatusDialog() {
      this.dialogVisible = true;
    },
    closeAccountStatusDialog() {
      this.dialogVisible = false;
    },
    // 立即申请
    nowApplication() {
      this.closeAccountStatusDialog();
    },
    // 取消申请
    cancelApplication() {
      this.closeAccountStatusDialog();
    },
    // 重新申请
    againApplication() {
      this.closeAccountStatusDialog();
    },
    // 创建企业成功
    createCompaniesSuccessful() {
      this.closeAccountStatusDialog();
    }
  }
};
</script>

<style lang="scss">
.account-status-com {
  .account-status {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #ffffff;
    .account-status-info {
      width: 300px;
    }
  }
}
.enterprise-auth-dialog {
  .select-identity-item {
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #6589f8;
  }
}
.create-companies-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>

<template>
  <div class="account-status-com height-full">
    <div v-if="showAccountStatus" class="account-status flex flex-center">
      <!--  个人账号-加入企业申请中-进入首页  -->
      <div v-if="state === 1" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            北京XXX公司
          </div>
          <div class="m-t-5">
            企业管理员审核中
          </div>
          <div class="m-t-5">
            若审核时间较长请拨打联系电话咨询
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round @click="cancelApplication">取消申请</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
      <!--  个人账号-未发起账号申请-进入首页  -->
      <div v-if="state === 2" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            您尚未成为正式客户
          </div>
          <div class="m-t-5">
            若有疑问请拨打客户热线进行咨询
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round @click="nowApplication">立即申请</el-button>
        </div>
        <div class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="becomeEnterprise">成为企业</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
      <!--  个人账号-商家管理员审核中-进入首页  -->
      <div v-if="state === 3" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            商家管理员审核中
          </div>
          <div class="m-t-5">
            若有疑问请拨打客户热线进行咨询
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round plain @click="becomeEnterprise">成为企业</el-button>
        </div>
        <div class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="cancelApplication">取消申请</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
      <!--  个人账号-商家管理员审核中-进入首页  -->
      <div v-if="state === 4" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            商家审核不通过
          </div>
          <div class="m-t-5">
            若有疑问请拨打客户热线
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round @click="againApplication">重新申请</el-button>
        </div>
        <div class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="becomeEnterprise">成为企业</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
      <!--  企业员工-未发起账号认证-进入首页  -->
      <div v-if="state === 5" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            您的企业尚未成为正式客户
          </div>
          <div class="m-t-5">
            若有疑问请拨打客户热线进行咨询
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round @click="nowApplication">立即申请</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
      <!--  企业员工-商家待审核-进入首页  -->
      <div v-if="state === 6" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            商家管理员审核中
          </div>
          <div class="m-t-5">
            若有疑问请拨打客户热线进行咨询
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round plain @click="cancelApplication">取消申请</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
      <!--  企业员工-商家拒绝-进入首页  -->
      <div v-if="state === 7" class="account-status-info flex-column flex-center">
        <img class="width-full" :src="underReviewIcon" alt="" />
        <div class="text-999 font-size-16 text-center">
          <div>
            商家审核不通过
          </div>
          <div class="m-t-5">
            若有疑问请拨打客户热线
          </div>
          <div v-if="companyInfo.servicePhone" class="m-t-5 text-primary">联系电话：{{ companyInfo.servicePhone }}</div>
        </div>
        <div class="m-t-20 width-full">
          <el-button class="width-full" type="primary" round @click="againApplication">重新申请</el-button>
        </div>
        <div v-if="companyInfo.servicePhone" class="m-t-10 width-full">
          <el-button class="width-full" type="primary" round plain @click="servicePhoneVisible = true">
            拨打联系电话
          </el-button>
        </div>
      </div>
    </div>
    <!--  拨打客服热线  -->
    <el-dialog title="拨打客服热线" :visible.sync="servicePhoneVisible" append-to-body width="400px">
      <div class="font-size-16 p-a-20">
        <span>请拨打：</span>
        <span class="text-primary">{{ companyInfo.servicePhone }}</span>
        <span>联系商城咨询</span>
      </div>
      <div slot="footer" class="flex flex-center">
        <el-button class="width-full" type="primary" @click="servicePhoneVisible = false">确定</el-button>
      </div>
    </el-dialog>
    <!--  企业认证  -->
    <el-dialog
      title="企业认证"
      :visible.sync="enterpriseAuthVisible"
      width="400px"
      custom-class="enterprise-auth-dialog"
      append-to-body
    >
      <div class="p-a-20">
        <div @click="createEnterprise" class="select-identity-item flex flex-center">
          <img class="w-30" src="@/assets/img/icons/icon-enterprise.png" alt="" />
          <div class="flex1 text-primary font-size-18 m-l-20">
            创建新企业
          </div>
          <svg
            t="1724048913638"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3530"
            width="32"
            height="32"
          >
            <path
              d="M514 114.3c-219.9 0-398.8 178.9-398.8 398.8 0 220 178.9 398.9 398.8 398.9s398.8-178.9 398.8-398.9c0-219.8-178.9-398.8-398.8-398.8z m232.1 440.4L582 718.8c-22.9 22.9-60.2 22.9-83.1 0-11.5-11.5-17.2-26.5-17.2-41.5s5.7-30.1 17.2-41.5l63.8-63.8H334c-32.5 0-58.8-26.3-58.8-58.8s26.3-58.8 58.8-58.8h228.7l-63.8-63.8c-22.9-22.9-22.9-60.2 0-83.1 22.9-22.9 60.2-22.9 83.1 0l164.1 164.1c22.9 23 22.9 60.2 0 83.1z"
              fill="#6589f8"
              p-id="3531"
            ></path>
          </svg>
        </div>
        <div v-if="false" @click="addEnterprise" class="select-identity-item flex flex-center m-t-20">
          <img class="w-30" src="@/assets/img/icons/icon-enterprises.png" alt="" />
          <div class="flex1 text-primary font-size-18 m-l-20">
            加入已有企业
          </div>
          <svg
            t="1724048913638"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3530"
            width="32"
            height="32"
          >
            <path
              d="M514 114.3c-219.9 0-398.8 178.9-398.8 398.8 0 220 178.9 398.9 398.8 398.9s398.8-178.9 398.8-398.9c0-219.8-178.9-398.8-398.8-398.8z m232.1 440.4L582 718.8c-22.9 22.9-60.2 22.9-83.1 0-11.5-11.5-17.2-26.5-17.2-41.5s5.7-30.1 17.2-41.5l63.8-63.8H334c-32.5 0-58.8-26.3-58.8-58.8s26.3-58.8 58.8-58.8h228.7l-63.8-63.8c-22.9-22.9-22.9-60.2 0-83.1 22.9-22.9 60.2-22.9 83.1 0l164.1 164.1c22.9 23 22.9 60.2 0 83.1z"
              fill="#6589f8"
              p-id="3531"
            ></path>
          </svg>
        </div>
      </div>
    </el-dialog>
    <!--  企业资料  -->
    <el-dialog
      title="企业资料"
      :visible.sync="createCompaniesVisible"
      width="800px"
      top="2vh"
      custom-class="create-companies-dialog"
      append-to-body
    >
      <div style="height: 700px">
        <CreateCompanies :is-dialog="true" @createCompaniesSuccessful="createCompaniesSuccessful" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CreateCompanies from "@/components/CreateCompanies";
import { customerApplication, customerApplicationCancel } from "@/api/base";
import store from "@/store";
const underReviewIcon = require("@/assets/img/under-review.png");

export default {
  name: "AccountStatus",
  components: {
    CreateCompanies
  },
  data() {
    return {
      desc: "账号状态",
      underReviewIcon: underReviewIcon,
      servicePhoneVisible: false,
      enterpriseAuthVisible: false,
      createCompaniesVisible: false
    };
  },
  computed: {
    companyInfo() {
      return this.$store.getters["companyInfo/companyInfo"];
    },
    userInfo() {
      return this.$store.getters["user/userInfo"];
    },
    showAccountStatus() {
      return this.userInfo.auditStatus !== "审核通过";
    },
    state() {
      if (this.userInfo.isCompany) {
        // 5,6,7
        if (this.userInfo.auditStatus === "未设置审核状态") {
          return 5;
        }
        if (this.userInfo.auditStatus === "审核中") {
          return 6;
        }
        if (this.userInfo.auditStatus === "审核失败") {
          return 7;
        }
      } else {
        // 1,2,3,4
        if (this.userInfo.auditStatus === "未设置审核状态") {
          return 2;
        }
        if (this.userInfo.auditStatus === "审核中") {
          return 3;
        }
        if (this.userInfo.auditStatus === "审核失败") {
          return 4;
        }
      }
      return 0;
    }
  },
  methods: {
    // 立即申请
    nowApplication() {
      customerApplication()
        .then(() => {
          this.$message.success("申请成功");
          // 获取用户信息
          this.$store.dispatch("user/getUserInfo");
          this.$emit("nowApplication");
        })
        .catch(err => {
          this.$message.error(err.message || "申请失败");
        });
    },
    // 取消申请
    cancelApplication() {
      this.$confirm("是否确认取消此次申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        customerApplicationCancel()
          .then(() => {
            this.$message.success("取消申请成功");
            // 获取用户信息
            this.$store.dispatch("user/getUserInfo");
            this.$emit("cancelApplication");
          })
          .catch(err => {
            this.$message.error(err.message || "取消申请失败！");
          });
      });
    },
    // 重新申请
    againApplication() {
      customerApplication()
        .then(() => {
          this.$message.success("重新申请成功");
          // 获取用户信息
          this.$store.dispatch("user/getUserInfo");
          this.$emit("againApplication");
        })
        .catch(err => {
          this.$message.error(err.message || "重新申请失败！");
        });
    },
    // 成为企业 企业认证流程
    becomeEnterprise() {
      this.enterpriseAuthVisible = true;
    },
    // 创建新企业
    createEnterprise() {
      this.enterpriseAuthVisible = false;
      this.createCompaniesVisible = true;
    },
    // 加入已有企业
    addEnterprise() {
      this.enterpriseAuthVisible = false;
    },
    // 创建企业成功
    createCompaniesSuccessful() {
      this.createCompaniesVisible = false;
      // 获取用户信息
      this.$store.dispatch("user/getUserInfo");
      // 获取商城资料
      store.dispatch("companyInfo/getCompanyInfo");
      this.$emit("createCompaniesSuccessful");
    }
  }
};
</script>

<style lang="scss">
.account-status-com {
  .account-status {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #ffffff;
    .account-status-info {
      width: 300px;
    }
  }
}
.enterprise-auth-dialog {
  .select-identity-item {
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #6589f8;
  }
}
.create-companies-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import companyInfo from "./modules/companyInfo";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 表格分页
    table: {
      pageSize: 20,
      pageSizeList: [10, 20, 50, 100, 200],
      layout: "total, sizes, prev, pager, next, jumper"
    },
    // 服务协议
    user_service_agreement: "https://dl.threesoft.cn/user_service_agreement.html",
    // 隐私政策
    privacy_policy: "https://dl.threesoft.cn/user_service_agreement.html",
    // 请求失败类型 保证事件只执行一次
    requestErrType: ""
  },
  mutations: {
    changeTablePageSize(state, data) {
      state.table.pageSize = data;
    }
  },
  actions: {},
  modules: {
    user,
    companyInfo
  }
});

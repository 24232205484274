import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import "./assets/css/global.less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/element-variables.scss";
import "@/assets/css/class.scss";

import "@/assets/iconFont/iconfont.css";
import VueClipboard from "vue-clipboard2";
import dayjs from "dayjs";
import tool from "./utils/tool";
import bus from "./utils/bus";
import hotkeys from "hotkeys-js";

import hztl from "@/components/hztl";
import "@/assets/css/hztl.scss";

Vue.prototype.$bus = new Vue();
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$tool = tool;
Vue.prototype.$bus = bus;
Vue.prototype.$hotkeys = hotkeys;

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(hztl);

// 获取设备号
store.dispatch("user/getDeviceKey");
// 获取商城资料
store.dispatch("companyInfo/getCompanyInfo");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

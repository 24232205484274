import request from "@/utils/request";

let addressListData = [];

export function getAddressList() {
  if (addressListData.length) {
    return addressListData;
  }
  //获取区域 list
  const addressList = [];
  request({
    url: "/mall/areas",
    method: "get"
  }).then(res => {
    const list = res;
    // 拼接省数据入
    for (const item of list) {
      if (item.type === 2) {
        addressList.push({
          value: item.id,
          label: item.name,
          children: []
        });
      }
    }
    // 省拼接好后循环拼接省对应的市
    for (const item of list) {
      for (const province of addressList) {
        if (item.parenId === province.value) {
          province.children.push({
            value: item.id,
            label: item.name,
            children: []
          });
        }
      }
    }
    // 最后拼接所有市对应的县或者区
    for (const item of list) {
      for (const province of addressList) {
        for (const city of province.children) {
          if (item.parenId === city.value) {
            city.children.push({
              value: item.id,
              label: item.name
            });
          }
        }
      }
    }
  });
  addressListData = addressList;
  return addressList;
}

export function getPositionAreaById(areaId, list) {
  // 三次循环匹配需要的position
  for (const provinceIndex of list) {
    for (const cityIndex of provinceIndex.children) {
      for (const countryIndex of cityIndex.children) {
        if (areaId === countryIndex.value) {
          return {
            position: [provinceIndex.value, cityIndex.value, areaId],
            name: provinceIndex.label + "/" + cityIndex.label + "/" + countryIndex.label
          };
        }
      }
    }
  }
  //循环结束后如果没有返回数据，则在这里返回一条数据
  return {
    position: [],
    name: ""
  };
}

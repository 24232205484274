import request from "@/utils/request";

// 账号组列表
export function getAccountsGroups(params) {
  return request({
    url: "/mall/accounts/groups",
    method: "get",
    data: params
  });
}

// 创建账号组
export function createAccountsGroups(params) {
  return request({
    url: "/mall/accounts/groups",
    method: "post",
    data: params
  });
}

// 删除账号组
export function deleteAccountsGroups(params) {
  return request({
    url: "/mall/accounts/groups",
    method: "delete",
    data: params
  });
}

// 切换账号快速登录
export function accountsQuicklyLogin(params) {
  return request({
    url: "/mall/accounts/quickly-login",
    method: "post",
    data: params
  });
}

// 当前用户公众号二维码
export function getAccountsTicket(params) {
  return request({
    url: "/mall/accounts/ticket",
    method: "get",
    data: params
  });
}

// 解除用户与微信服务关联
export function unbindAccounts(params) {
  return request({
    url: "/mall/accounts/unbind",
    method: "post",
    data: params
  });
}

import getBrowserFinger from "device-key";
import { getAccountsProfile } from "@/api/base";
import { getAccountsGroups } from "@/api/accounts";
import { getShoppingCartList } from "@/api/shoppingCart";

export default {
  namespaced: true,
  state: {
    // 设备key
    deviceKey: "",
    // 购物车数量
    shoppingCartNum: 0,
    // 用户信息
    userInfo: {
      userId: 0,
      account: "",
      phone: "",
      username: "",
      staffId: 0,
      companyName: "",
      isManager: false,
      auditStatus: "",
      isCompany: false,
      erp: {
        // 绑定ERP信息
        accountSetName: "", // 账套名称
        companyName: "", // 公司名称
        managerAccount: "", // 管理员账号
        managerName: "", // 管理员名称
        managerPhone: "" // 管理员手机号
      },
      permissions: {
        carts: {
          selfBase: false,
          selfSubmit: false,
          othersRead: false,
          othersEdit: false,
          othersDelete: false,
          othersSubmit: false
        },
        orders: {
          selfBase: false,
          selfCancel: false,
          othersRead: false,
          othersCancel: false
        },
        others: {
          statement: false,
          company: false,
          staffs: false
        }
      },
      oplatformAccount: {
        // 微信公众号
        offiaccount: {
          appid: "", // 关联公众号的appid
          appName: "", // 当前关注公众号名称
          isBind: false // 当前用户是否绑定公众号
        }
      }
    },
    // 账号组列表
    accountsGroups: []
  },
  getters: {
    userInfo: state => {
      return state.userInfo;
    },
    permissions: state => {
      return state.userInfo.permissions;
    },
    shoppingCartNum: state => {
      return state.shoppingCartNum;
    },
    deviceKey: state => {
      return state.deviceKey;
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      const res = await getAccountsProfile();
      commit("setUserInfo", res || {});
      return res;
    },
    async getDeviceKey({ commit }) {
      const res = await Promise.all([getBrowserFinger()]);
      commit("setDeviceKey", res[0]);
    },
    async setShoppingCartNum({ commit }, num) {
      commit("setShoppingCartNum", num);
    },
    async getShoppingCartList({ commit }) {
      const data = {
        keyword: "", // 关键字
        isNormalBuy: false, // 是否常购配件，true是
        isPromotion: 0, // 促销: 0全部/1是/2否
        isReducePrice: false // 是否降价商品，true是
      };
      const res = await getShoppingCartList(data);
      if (res) {
        commit("setShoppingCartNum", res.totalQty || 0);
      }
    },
    async getAccountsGroups({ commit }) {
      const res = await getAccountsGroups();
      if (res && res.rows && res.rows.length) {
        commit("setAccountsGroups", res.rows);
      } else {
        commit("setAccountsGroups", []);
      }
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setDeviceKey(state, deviceKey) {
      state.deviceKey = deviceKey;
      window.deviceKey = deviceKey;
    },
    setShoppingCartNum(state, num) {
      state.shoppingCartNum = num;
    },
    setAccountsGroups(state, list) {
      state.accountsGroups = list || [];
    }
  }
};

import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { envConfig } from "envConfigPath";
import bus from "@/utils/bus";
import router from "@/router";
import store from "@/store";

export const baseURL = ["development"].includes(process.env.NODE_ENV)
  ? envConfig.baseURL
  : `https://${window.location.hostname}/api`;

// 创建axios实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 30000, // 请求超时时间
  withCredentials: true // 请求为跨域类型时是否在请求中协带cookie
});

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    if (!store.state.requestErrType) {
      return config;
    }
  },
  error => {
    // 对请求错误执行的操作
    if (!store.state.requestErrType) {
      return Promise.reject(error);
    }
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  response => {
    const { responseType } = response.config;
    const res = response.data;
    if (responseType === "blob") {
      if (res.message) {
        Message({
          message: res.message,
          type: "error",
          duration: 3500,
          showClose: true
        });
        return Promise.reject(res);
      } else {
        return Promise.resolve(res);
      }
    } else if (res.code === "ok") {
      return Promise.resolve(res.data);
    } else if (res.code === "force-reset-password") {
      if (!store.state.requestErrType) {
        store.state.requestErrType = res.code;
        bus.$emit(res.code, true);
        setTimeout(() => {
          store.state.requestErrType = "";
        }, 1000);
      }
      // 中断Promise
      return new Promise(() => {});
    } else if (res.code === "beyond-max-login" && !response.config.url.includes("/mall/accounts/quickly-login")) {
      if (!store.state.requestErrType) {
        store.state.requestErrType = res.code;
        if (router.currentRoute.path.includes("/login")) {
          bus.$emit(res.code, true);
        } else {
          router.push({
            path: "/login",
            query: {
              type: "beyond-max-login"
            }
          });
        }
        setTimeout(() => {
          store.state.requestErrType = "";
        }, 1000);
      }
      // 中断Promise
      return new Promise(() => {});
    } else if (res.code === "token-err" || res.code === "seller-error") {
      if (!store.state.requestErrType) {
        store.state.requestErrType = res.code;
        MessageBox({
          type: "warning",
          message: res.message,
          title: "提示",
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showClose: false,
          callback: () => {
            store.state.requestErrType = "";
          }
        });
        router.push({
          path: "/login"
        });
        setTimeout(() => {
          store.state.requestErrType = "";
          // 获取商城资料
          store.dispatch("companyInfo/getCompanyInfo");
        }, 1000);
      }
      // 中断Promise
      return new Promise(() => {});
    } else if (res.code === "error" && res.message === "商家账号，不可访问客户端") {
      if (!store.state.requestErrType) {
        store.state.requestErrType = res.code;
        // 商家账号，不可访问客户端
        if (!router.currentRoute.path.includes("/login")) {
          router.push({
            path: "/login"
          });
        }
        setTimeout(() => {
          store.state.requestErrType = "";
        }, 1000);
      }
      // 中断Promise
      return new Promise(() => {});
    } else {
      return Promise.reject(res);
    }
  },
  error => {
    if (!store.state.requestErrType) {
      return Promise.reject(error);
    }
  }
);

export default service;

import { getCompanies } from "@/api/order";
const siruiLogoIcon = require("@/assets/img/sirui.png");
const defaultLogoIcon = require("@/assets/img/default-logo.png");

export default {
  namespaced: true,
  state: {
    // 商城资料
    company: {
      id: 0,
      authId: 0,
      name: "",
      shortName: "",
      storeName: "",
      type: "",
      areaId: 0,
      area: "",
      address: "",
      flags: 0,
      logo: "",
      emblem: "",
      poster: "",
      description: "",
      servicePhone: "",
      offiaccount: "",
      brands: [],
      payments: [],
      setting: {
        id: 0,
        dbAccount: "",
        companyId: 0,
        properties: [],
        store: [],
        sectionPrices: [],
        credits: [],
        creditNotifyDays: [],
        orderCancels: [],
        autoReceiveOrder: 0,
        upRate: "",
        flags: 0,
        paymentType: "",
        invoiceType: "",
        priceType: "",
        paymentDays: "",
        discount: "",
        stockShowMode: 0,
        stockShowSettings: [],
        checkStock: false,
        autoConfirmOrder: false,
        allowZeroStock: false,
        allowZeroPrice: false,
        autoAuditCustomer: false,
        autoConfirmOfflinePay: false,
        stockNotEnoughShowNone: false,
        checkCustomerWarehouse: false,
        checkCartStock: false,
        showLinePrice: false,
        enableSection: false,
        enableFuture: false,
        enbaleSubscript: false,
        newAutoUp: false,
        allowInformalCustomer: false,
        allowStockInfo: false,
        allowPriceInfo: false,
        allowExternalRegistration: false,
        vin: false,
        vinCustomerLimits: false,
        stockShowModeWarehouse: false,
        stockShowModeProductBatch: false
      },
      qrCode: {
        miniprogram: "",
        offiaccount: ""
      }
    },
    // 徽章
    emblem: "",
    // 商城名称
    storeName: "",
    // 公共链接：public | 私域链接：private
    SYSTYPE: "public"
  },
  getters: {
    // 是否允许非正式客户访问
    noLoginLook: state => {
      const condition1 = state.company.setting && state.company.setting.allowInformalCustomer;
      const condition2 = localStorage.isLogin !== "true";
      return condition1 && condition2;
    },
    companyInfo: state => {
      return state.company;
    }
  },
  actions: {
    async getCompanyInfo({ commit }) {
      const res = await getCompanies();
      commit("setCompanyInfo", res || {});
    }
  },
  mutations: {
    setCompanyInfo(state, data) {
      // 处理公众号二维码与小程序二维码
      if (data.qrCode) {
        if (data.qrCode.miniprogram) {
          data.qrCode.miniprogram = window.tool.imageCrossReplace(data.qrCode.miniprogram);
        } else {
          data.qrCode.miniprogram = "";
        }
        if (data.qrCode.offiaccount) {
          data.qrCode.offiaccount = window.tool.imageCrossReplace(data.qrCode.offiaccount);
        } else {
          data.qrCode.offiaccount = "";
        }
      } else {
        data.qrCode = {
          miniprogram: "",
          offiaccount: ""
        };
      }
      state.company = { ...state.company, ...data };
      let emblem = "";
      let storeName = "";
      if (["www.srgou.com", "www.qa.srgou.com"].includes(window.location.hostname)) {
        // 公共链接
        state.SYSTYPE = "public";

        if (state.company.emblem) {
          emblem = decodeURIComponent(state.company.emblem); // 取有的
        } else {
          emblem = siruiLogoIcon; // 默认的
        }
        if (state.company.storeName) {
          storeName = state.company.storeName; // 取有的
        } else {
          storeName = "思锐云订货系统"; // 默认的
        }
      } else {
        // 私域链接
        state.SYSTYPE = "private";

        if (state.company.emblem) {
          emblem = decodeURIComponent(state.company.emblem); // 取有的
        } else {
          emblem = defaultLogoIcon; // 占位的
        }
        if (state.company.storeName) {
          storeName = state.company.storeName; // 取有的
        } else {
          storeName = ""; // 空
        }
      }
      // 徽章
      state.emblem = emblem;
      // 商城名称
      state.storeName = storeName;
      // 设置标签页的title
      document.title = state.company.storeName;
    }
  }
};

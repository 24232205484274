import request from "@/utils/request";

// 购物车列表
export function getShoppingCartList(params) {
  return request({
    url: "/mall/carts",
    method: "get",
    params: params
  });
}

// 添加到购物车
export function addShoppingCart(params) {
  return request({
    url: "/mall/carts",
    method: "post",
    data: params
  });
}

// 修改购物车数量
export function editShoppingCart(params) {
  return request({
    url: "/mall/carts/" + params.id,
    method: "post",
    data: params
  });
}

// 删除购物车
export function deleteShoppingCart(params) {
  return request({
    url: "/mall/carts/delete",
    method: "post",
    data: params
  });
}

// 选中购物车
export function shoppingCartChecked(params) {
  return request({
    url: "/mall/carts/checked",
    method: "post",
    data: params
  });
}

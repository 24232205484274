<template>
  <div class="company-data" :class="isDialog ? 'company-data-dialog' : ''">
    <el-card class="height-full">
      <div v-if="!isDialog" slot="header">
        <div class="flex flex-center">
          <span @click="toSelectIdentity" class="absolute top-0 left-0 cursor-pointer m-t-15 m-l-10">
            <i class="el-icon-arrow-left font-size-30"></i>
          </span>
          <span class="font-size-18">企业资料</span>
        </div>
      </div>
      <div class="height-full flex flex-column flex-center">
        <div class="company-data-box flex flex-column height-full">
          <div class="flex1 overflow-auto p-r-20">
            <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
              <el-form-item prop="type" label="企业类型">
                <el-select v-model="formData.type" placeholder="请选择企业类型">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="name" label="企业名称">
                <el-input v-model="formData.name" clearable maxlength="20" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item prop="bizLicenceCode" label="统一社会信用码">
                <el-input
                  v-model="formData.bizLicenceCode"
                  clearable
                  maxlength="18"
                  placeholder="请输入统一社会信用码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="contacts" label="联系人">
                <el-input v-model="formData.contacts" clearable maxlength="6" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item prop="phone" label="联系电话">
                <el-input v-model="formData.phone" clearable maxlength="11" placeholder="请输入联系电话"></el-input>
              </el-form-item>
              <el-form-item prop="areaId" label="所在地区">
                <SelectArea
                  class="width-full"
                  :areaData="formData.areaIds"
                  size="medium"
                  placeholder="请选择所在地区"
                  @change="areaChange"
                />
              </el-form-item>
              <el-form-item prop="address" label="详细地址">
                <el-input
                  v-model="formData.address"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 6 }"
                  maxlength="50"
                  placeholder="请输入详细地址"
                ></el-input>
              </el-form-item>
              <el-form-item prop="licencesPic" label="营业执照">
                <div class="flex">
                  <UploadFileImg
                    style="width: 80px;margin-right: 8px;"
                    :maxSize="2"
                    listType="picture-card"
                    :limit="1"
                    :fileList="imgList"
                    :isMultiple="false"
                    :showFileList="true"
                    :hideFlag="true"
                    @getFileList="fileList => getImgFileList(fileList)"
                  />
                  <div>图片大小2M以内</div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="m-t-10">
            <el-button v-loading="loading" @click="save" class="width-full" type="primary" round>
              保存
            </el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import SelectArea from "@/components/selectArea/SelectArea.vue";
import UploadFileImg from "@/components/Upload/UploadFileImg.vue";
import { mobileValidate } from "@/utils/validate";
import { createCompanies } from "@/api/base";

export default {
  name: "CreateCompanies",
  components: {
    SelectArea,
    UploadFileImg
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      desc: "创建企业资料",
      loading: false,
      imgList: [],
      formData: {
        name: "",
        bizLicenceCode: "",
        contacts: "",
        phone: "",
        areaId: 0,
        address: "",
        type: "",
        licencesPic: "",

        areaIds: []
      },
      rules: {
        type: [{ required: true, trigger: "change", message: "请选择企业类型" }],
        name: [{ required: true, trigger: "change", message: "请输入企业名称" }],
        contacts: [{ required: true, trigger: "change", message: "请输入联系人" }],
        phone: [
          { required: true, trigger: "change", message: "请输入联系电话" },
          { validator: mobileValidate, trigger: "blur" }
        ],
        areaId: [
          { required: true, trigger: "change", message: "请选择所在地区" },
          {
            validator: (rule, value, callback) => {
              if (value) {
                callback();
              } else {
                callback(new Error("请选择所在地区"));
              }
            },
            trigger: "blur"
          }
        ],
        address: [{ required: true, trigger: "change", message: "请输入详细地址" }]
      },
      // 企业类型
      typeOptions: [
        { value: "garage", name: "汽修厂" },
        { value: "dealer", name: "经销商" },
        { value: "manufacturer", name: "生产厂家" }
      ]
    };
  },
  methods: {
    toSelectIdentity() {
      this.$emit("toSelectIdentity");
    },
    areaChange(area) {
      if (area && area.areaIds.length) {
        this.formData.areaId = area.areaIds[area.areaIds.length - 1];
        this.formData.areaIds = area.areaIds;
      } else {
        this.formData.areaId = "";
        this.formData.areaIds = [];
      }
    },
    getImgFileList(fileList) {
      this.imgList = fileList;
      if (this.imgList.length) {
        this.formData.licencesPic = this.imgList[0].origin;
      } else {
        this.formData.licencesPic = "";
      }
    },
    save() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.createCompanies();
        }
      });
    },
    createCompanies() {
      this.loading = true;
      createCompanies(this.formData)
        .then(() => {
          this.loading = false;
          this.$message.success("提交申请成功，请等待商家审核！");
          setTimeout(() => {
            this.$emit("createCompaniesSuccessful");
          }, 500);
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "提交失败");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.company-data {
  inset: 0;
  width: 100% !important;
  height: 100%;
  padding: 0 !important;
  position: absolute;
  background-color: #ffffff;
  /deep/ .el-card {
    display: flex;
    flex-direction: column;
    .el-card__body {
      flex: 1;
      overflow: hidden;
    }
  }
  .company-data-box {
    width: 500px;
  }
}
.company-data-dialog {
  position: relative;
}
</style>

import request from "@/utils/request";

/**
 * token登录
 */
export function tokenLogin(data) {
  return request({
    url: "/mall/accounts/token-login",
    method: "post",
    data: data
  });
}

/**
 * 账号密码登录
 */
export function passwordLogin(data) {
  return request({
    url: "/mall/accounts/password-login",
    method: "post",
    data: data
  });
}

/**
 * 短信验证码登录
 */
export function SmsLogin(data) {
  return request({
    url: "/mall/accounts/sms-login",
    method: "post",
    data: data
  });
}

/**
 * 退出登录
 */
export function logout() {
  return request({
    url: "/mall/accounts/logout",
    method: "post"
  });
}

/**
 * 外部注册账号
 */
export function register(data) {
  return request("/mall/accounts/register-account", {
    method: "POST",
    data: data
  });
}

/**
 * 外部客户申请
 */
export function customerApplication() {
  return request("/mall/accounts/customer-application", {
    method: "GET"
  });
}

/**
 * 外部注册取消申请
 */
export function customerApplicationCancel() {
  return request("/mall/accounts/customer-application-cancel", {
    method: "GET"
  });
}

/**
 * 创建企业资料
 */
export function createCompanies(data) {
  return request("/mall/accounts/companies/create", {
    method: "POST",
    data: data
  });
}

/**
 * 查询企业资料
 */
export function getCompaniesInfo() {
  return request("/mall/accounts/companies/info", {
    method: "GET"
  });
}

/**
 * 更新企业资料
 */
export function updateCompanies(data) {
  return request("/mall/accounts/companies/update", {
    method: "POST",
    data: data
  });
}

/**
 * 个人资料
 */
export function getAccountsProfile() {
  return request({
    url: "/mall/accounts/profile",
    method: "get"
  });
}

/**
 * 通过手机号精确匹配获取账号
 */
export function getAccountsList(data) {
  return request({
    url: "/mall/accounts/list-by-phone",
    method: "get",
    params: data
  });
}

/**
 * 使用原密码更新密码
 */
export function updatePassword(data) {
  return request({
    url: "/mall/accounts/update-password",
    method: "post",
    data: data
  });
}

/**
 * 使用短信验证码更新密码
 */
export function updateResetPassword(data) {
  return request({
    url: "/mall/accounts/reset-password",
    method: "post",
    data: data
  });
}

/**
 * 更新手机号
 */
export function updatePhone(data) {
  return request({
    url: "/mall/accounts/update-phone",
    method: "post",
    data: data
  });
}

/**
 * 检查手机号
 */
export function checkPhone(data) {
  return request({
    url: "/mall/accounts/check-phone",
    method: "post",
    data: data
  });
}

/**
 * 配件分类树
 */
export function getCategoriesTree() {
  return request({
    url: "/mall/dicts/categories",
    method: "get"
  });
}

/**
 * 仓库列表
 */
export function getWarehousesList() {
  return request({
    url: "/mall/dicts/warehouses",
    method: "get"
  });
}

/**
 * 性质列表
 */
export function getPropertiesList() {
  return request({
    url: "/mall/dicts/properties",
    method: "get"
  });
}

/**
 * 字典列表
 */
export function getDictsByType(params) {
  return request({
    url: "/mall/dicts",
    method: "get",
    params
  });
}

/**
 * 获取查询设置
 */
export function getAccountsSetting() {
  return request({
    url: "/mall/accounts/setting",
    method: "get"
  });
}

/**
 * 更新查询设置
 */
export function updateAccountsSetting(data) {
  return request({
    url: "/mall/accounts/setting",
    method: "post",
    data: data
  });
}

/**
 * 图片上传
 */
export function uploadImgFile(params) {
  const form = new FormData();
  if (params.type) {
    form.append("objectType", params.type);
  }
  if (Array.isArray(params.file)) {
    params.file.forEach(file => {
      form.append("file", file, file.name);
    });
  } else {
    form.append("file", params.file, params.file.name);
  }
  return request({
    method: "post",
    url: "/upload",
    headers: { "Content-Type": "multipart/form-data" },
    data: form
  });
}

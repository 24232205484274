export const phoneValidateTips = "手机号格式不正确";
export function phoneValidate(phone) {
  return /^1[345789]\d{9}$/.test(phone);
}

export const passwordValidateTips = "密码8~16个字符，支持数字、大小写字母和英文符号";
export const passwordValidateTipsTwo = "密码6~16个字符，支持数字、大小写字母和英文符号";
export function passwordValidate(password) {
  const re = /^[\w[\]\\!@#$%&^*-_=+|;:/?.()<>{}]{8,16}$/;
  return re.test(password);
}
export function passwordValidateTwo(password) {
  const re = /^[\w[\]\\!@#$%&^*-_=+|;:/?.()<>{}]{6,16}$/;
  return re.test(password);
}

// 短信验证码
export const captchaValidate = (rule, value, callback) => {
  const reg = /^[0-9]+$/;
  if (value === "") {
    callback(new Error("请输入验证码"));
  } else if (value.length < 6) {
    callback(new Error("请输入6位验证码"));
  } else if (!reg.test(value)) {
    callback(new Error("请输入6位验证码"));
  } else {
    callback();
  }
};

// 手机号
export const mobileValidate = (rule, value, callback) => {
  const reg = /^1[3-9]\d{9}$/;
  if (!value || !value.length) {
    callback(new Error("手机号码输入不合法"));
    return;
  }
  if (value === "") {
    callback(new Error("手机号码输入不合法"));
  } else if (value.length !== 11 || !reg.test(value)) {
    callback(new Error("手机号码输入不合法"));
  } else {
    callback();
  }
};

import Select from "./select";
import { Table, Pagination, EditColumns } from "./table";
const components = [Select, Table, Pagination, EditColumns];

const hztlInstall = function(Vue) {
  components.forEach(component => {
    Vue.component(component.name, component);
  });
};

export default {
  install: hztlInstall
};

<script>
import { mapState } from "vuex";

export default {
  name: "HtPagination",
  componentName: "HtPagination",
  computed: {
    ...mapState(["table"])
  },
  methods: {
    handleSizeChange(size) {
      this.$store.commit("changeTablePageSize", size);
      this.pageChangeHandle(1);
    },
    pageChangeHandle(page) {
      this.$emit("pageChange", page);
    }
  },
  render() {
    return (
      <div class="pagination">
        <div class="before-pagination">{this.$scopedSlots.default && this.$scopedSlots.default()}</div>
        <el-pagination
          ref="pagination"
          attrs={this.$attrs}
          page-sizes={this.table.pageSizeList}
          pageSize={this.table.pageSize}
          layout={this.$attrs.paginationLayout || this.table.layout}
          hide-on-single-page={this.$attrs.hideOnSinglePage}
          on-current-change={this.pageChangeHandle}
          on-size-change={this.handleSizeChange}
        />
      </div>
    );
  }
};
</script>

import { envConfig } from "envConfigPath";

const tool = {
  /**
   * 时间区
   */
  dateFormat: "yyyy-MM-dd",
  // 获取当前时间戳
  getTimeStamp: function() {
    return new Date().getTime();
  },
  // 获取日期时间并传入指定格式
  getDateTime: function(format, date) {
    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }
    if (!format) {
      format = "yyyy-MM-dd hh:mm:ss";
    }
    const o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
      }
    }
    return format;
  },
  // 获取日期
  getDate: function(date) {
    return tool.getDateTime(tool.dateFormat, date);
  },
  // 计算两个日期相差的天数
  getDays: function(date1, date2) {
    const date1Time = new Date(date1).getTime();
    const date2Time = new Date(date2).getTime();
    return (date2Time - date1Time) / (1000 * 60 * 60 * 24);
  },
  // 获取当前时间是星期几
  getWeek: function() {
    const week = new Date().getDay();
    return ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"][week];
  },
  /**
   * 获取今天的日期范围
   */
  getTodaydayRange: function() {
    const date = new Date();
    const startDay = tool.getDateTime(tool.dateFormat, date);
    const endDay = tool.getDateTime(tool.dateFormat, date);
    return [startDay, endDay];
  },
  /**
   * 获取昨天的日期范围
   */
  getYesterdayRange: function() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const startDay = tool.getDateTime(tool.dateFormat, date);
    const endDay = tool.getDateTime(tool.dateFormat, date);
    return [startDay, endDay];
  },
  /**
   * 获取本周日期范围
   */
  getThisWeekDateRange: function() {
    const now = new Date();
    const nowTime = now.getTime();
    let day = now.getDay();
    if (day === 0) {
      day = 7;
    }
    const oneDayTime = 24 * 60 * 60 * 1000;
    const MondayTime = nowTime - (day - 1) * oneDayTime;
    const SundayTime = nowTime + (7 - day) * oneDayTime;
    const startDay = tool.getDateTime(tool.dateFormat, new Date(MondayTime));
    const endDay = tool.getDateTime(tool.dateFormat, new Date(SundayTime));
    return [startDay, endDay];
  },
  /**
   * 获取上周日期范围
   */
  getLastWeekDateRange: function() {
    const today = new Date();
    let dayOfWeek = today.getDay();
    if (dayOfWeek === 0) {
      dayOfWeek = 7;
    }
    /**
     * 获取上周的第一天
     */
    const startDay = tool.getDateTime(
      tool.dateFormat,
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek - 6)
    );
    /**
     * 获取上周的最后一天
     */
    const endDay = tool.getDateTime(
      tool.dateFormat,
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek)
    );
    return [startDay, endDay];
  },
  /**
   * 获取本月日期范围
   * 获取某月日期范围
   */
  getThisMonthDateRange: function(date) {
    let today = new Date();
    if (date) {
      today = new Date(date);
    }
    const startDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear(), today.getMonth(), 1)); // 当前月份的第一天
    const endDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear(), today.getMonth() + 1, 0)); // 当前月份的最后一天
    return [startDay, endDay];
  },
  /**
   * 获取上月日期范围
   */
  getLastMonthDateRange: function() {
    const today = new Date();
    const startDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear(), today.getMonth() - 1, 1)); // 上个月的第一天
    const endDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear(), today.getMonth(), 0)); // 上个月的最后一天
    return [startDay, endDay];
  },
  /**
   * 获取本年日期范围
   */
  getThisYearDateRange: function() {
    const today = new Date();
    const startDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear(), 0, 1)); // 当前年份的第一天
    const endDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear(), 11, 31)); // 当前年份的最后一天
    return [startDay, endDay];
  },
  /**
   * 获取上年日期范围
   */
  getLastYearDateRange: function() {
    const today = new Date();
    const startDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear() - 1, 0, 1)); // 上年份的第一天
    const endDay = tool.getDateTime(tool.dateFormat, new Date(today.getFullYear() - 1, 11, 31)); // 上年份的最后一天
    return [startDay, endDay];
  },
  /**
   *  通过开始、结束日期获取当前是今天、昨天、本周、上周。。。
   * @param {*} start
   * @param {*} end
   * @returns
   */
  getDateNameByRange: function(start, end) {
    if (start === null && end === null) {
      return "";
    }
    const getTodaydayRange = tool.getTodaydayRange();
    const getYesterdayRange = tool.getYesterdayRange();
    const getThisWeekDateRange = tool.getThisWeekDateRange();
    const getLastWeekDateRange = tool.getLastWeekDateRange();
    const getThisMonthDateRange = tool.getThisMonthDateRange();
    const getLastMonthDateRange = tool.getLastMonthDateRange();
    const getThisYearDateRange = tool.getThisYearDateRange();
    const getLastYearDateRange = tool.getLastYearDateRange();
    if (start === getTodaydayRange[0] && end === getTodaydayRange[1]) {
      return "今天";
    } else if (start === getYesterdayRange[0] && end === getYesterdayRange[1]) {
      return "昨天";
    } else if (start === getThisWeekDateRange[0] && end === getThisWeekDateRange[1]) {
      return "本周";
    } else if (start === getLastWeekDateRange[0] && end === getLastWeekDateRange[1]) {
      return "上周";
    } else if (start === getThisMonthDateRange[0] && end === getThisMonthDateRange[1]) {
      return "本月";
    } else if (start === getLastMonthDateRange[0] && end === getLastMonthDateRange[1]) {
      return "上月";
    } else if (start === getThisYearDateRange[0] && end === getThisYearDateRange[1]) {
      return "今年";
    } else if (start === getLastYearDateRange[0] && end === getLastYearDateRange[1]) {
      return "上年";
    } else {
      return "";
    }
  },
  /**
   * 通过今天、昨天、本周、上周。。。返回日期范围
   * @param {*} title
   * @returns
   */
  getDateRangeByName: function(title) {
    if (title === "今天") {
      return this.getTodaydayRange();
    } else if (title === "昨天") {
      return this.getYesterdayRange();
    } else if (title === "本周") {
      return this.getThisWeekDateRange();
    } else if (title === "上周") {
      return this.getLastWeekDateRange();
    } else if (title === "本月") {
      return this.getThisMonthDateRange();
    } else if (title === "上月") {
      return this.getLastMonthDateRange();
    } else if (title === "今年") {
      return this.getThisYearDateRange();
    } else if (title === "上年") {
      return this.getLastYearDateRange();
    } else {
      return ["", ""];
    }
  },
  /**
   * 获取当前月的天数
   */
  getDaysInMonth: function(year, month) {
    return new Date(year, month, 0).getDate();
  },
  /**
   * 数字区
   */
  // 判断一个数值是否是number类型
  isNumber: function(num) {
    return typeof num === "number";
  },
  // 判断一个数值是否是NAN
  isNaN: function(num) {
    return isNaN(num);
  },
  /**
   * 字符串区
   */
  // 字符串替换所有
  replaceAllString: function(str = "", oldStr, newStr) {
    return str.replace(new RegExp(oldStr, "g"), newStr);
  },
  // 字符串截取
  getSubString: function(str = "", start, end) {
    return str.substring(start, end);
  },
  // 生成指定长度随机字符串
  getRandomString: function(length) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const maxPos = chars.length;
    let pwd = "";
    for (let i = 0; i < length; i++) {
      if (i === 0) {
        pwd += chars.charAt(Math.floor(Math.random() * (maxPos - 10)));
      } else {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
    }
    return pwd;
  },
  /**
   * 数组区
   */
  // 数组对象添加元素
  addArrayItem: function(array = [], item) {
    return array.push(item);
  },
  // 数组指定位置添加元素
  addArrayItemAt: function(array = [], item, index) {
    return array.splice(index, 0, item);
  },
  // 数组删除元素
  deleteArrayItem: function(array = [], index) {
    return array.splice(index, 1);
  },
  // 数组替换元素
  replaceArrayItem: function(array = [], index, item) {
    return array.splice(index, 1, item);
  },
  // 数组截取
  sliceArray: function(array = [], start, end) {
    return array.slice(start, end);
  },
  // 数组元素交换位置
  swapArrayItem: function(array = [], index1, index2) {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    return array;
  },
  // 数组升序降序排序
  sortArray: function(array = [], type) {
    if (type === "asc") {
      return array.sort((a, b) => {
        return a - b;
      });
    }
    if (type === "desc") {
      return array.sort((a, b) => {
        return b - a;
      });
    }
  },
  // 数组去重
  uniqueArray: function(array = []) {
    return Array.from(new Set(array));
  },
  // 数组求最大值
  maxArray: function(array = []) {
    return Math.max(...array);
  },
  // 数组求最小值
  minArray: function(array = []) {
    return Math.min(...array);
  },
  // 数组求和
  sumArray: function(array = [1]) {
    return array.reduce((pre, cur) => {
      return pre + cur;
    });
  },
  // 数组平均值
  averageArray: function(array = [1]) {
    return this.sumArray(array) / array.length;
  },
  // 数组求中位数
  medianArray: function(array = [1]) {
    const length = array.length;
    if (length % 2 === 0) {
      return (array[length / 2 - 1] + array[length / 2]) / 2;
    } else {
      return array[(length - 1) / 2];
    }
  },
  // 数组求最大值下标
  maxArrayIndex: function(array = []) {
    return array.indexOf(this.maxArray(array));
  },
  // 数组求最小值下标
  minArrayIndex: function(array = []) {
    return array.indexOf(this.minArray(array));
  },
  // 判断数组是否包含某个元素
  isContain: function(arr = [], item) {
    return arr.indexOf(item) > -1;
  },
  // 数组扁平化
  flattenArray: function(array = []) {
    return array.flat(Infinity);
  },
  /**
   * 对象区
   */
  // 数组对象某个属性求和
  sumObjectProperty: function(obj = {}, key) {
    return obj.reduce((pre, cur) => {
      return pre + cur[key];
    });
  },
  // 数组对象某个属性排序
  sortObject: function(obj = {}, key, type) {
    if (type === "asc") {
      return obj.sort((item1, item2) => {
        return item1[key] - item2[key];
      });
    }
    if (type === "desc") {
      return obj.sort((item1, item2) => {
        return item2[key] - item1[key];
      });
    }
  },
  // 数组对象某个属性去重
  uniqueObject: function(obj = {}, key) {
    const objs = {};
    return obj.reduce((cur, next) => {
      objs[next[key]] ? "" : (objs[next[key]] = true && cur.push(next));
      return cur;
    }, []);
  },
  // 对象合并
  mergeObject: function(obj1 = {}, obj2 = {}) {
    return Object.assign(obj1, obj2);
  },
  // 对象某个属性值转数组
  objectToArray: function(obj = {}, key) {
    return Object.keys(obj).map(item => {
      return obj[item][key];
    });
  },
  // 判断对象是否是空对象
  isEmptyObject: function(obj = {}) {
    return Object.keys(obj).length === 0;
  },
  // 判断对象某个属性是否存在
  isObjectPropertyExist: function(obj = {}, key) {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(key);
  },
  // 对象深层冻结
  deepFreeze: function(obj = {}) {
    Object.freeze(obj);
    // eslint-disable-next-line no-unused-vars
    Object.keys(obj).forEach(function(key, value) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] && typeof obj[key] === "object") {
        this.deepFreeze(obj[key]);
      }
    });
  },
  // 对象深层拷贝不包含函数和undefined
  deepClone: function(obj = {}) {
    return JSON.parse(JSON.stringify(obj));
  },
  // 对象深层递归拷贝包含函数和undefined
  deepCloneRecursive: function(obj) {
    const result = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          if (obj[key] === null) {
            result[key] = null;
          } else {
            result[key] = this.deepCloneRecursive(obj[key]);
          }
        } else {
          result[key] = obj[key];
        }
      }
    }
    return result;
  },
  /**
   *url参数区
   */
  // 获取url单个参数
  getUrlParam: function(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  },
  // 获取url所有参数
  getUrlParams: function() {
    const url = window.location.search; //获取url中"?"符后的字串
    const theRequest = {};
    if (url.indexOf("?") !== -1) {
      const str = url.substr(1).split("&");
      for (let i = 0; i < str.length; i++) {
        theRequest[str[i].split("=")[0]] = decodeURIComponent(str[i].split("=")[1]);
      }
    }
    return theRequest;
  },
  // 防抖
  debounceFn: function(fn, delay) {
    let timer = null;
    return function() {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, arguments);
        timer = null;
      }, delay);
    };
  },
  // 节流
  throttlingFn: function(fn, interval) {
    let startTime = 0;
    return function() {
      const nowTime = new Date().getTime();
      const waitTime = interval - (nowTime - startTime);
      if (waitTime <= 0) {
        fn.apply(this, arguments);
        startTime = nowTime;
      }
    };
  },
  // 处理多级字段
  getPropValue: function(prop) {
    if (Array.isArray(prop)) {
      return prop.join(".");
    }
    return prop;
  },
  //获取两个数的最小公倍数函数
  getLCM: function(num1, num2) {
    let lcm = 0; // 初始化最大公因子为0

    for (let i = Math.max(num1, num2); ; i++) {
      if (i % num1 === 0 && i % num2 === 0) {
        lcm = i;
        break;
      }
    }
    return lcm;
  },
  // 微信公众号图片跨域
  imageCrossReplace: function(url) {
    if (url && url.includes("data:image")) {
      return url;
    }
    const baseUrl = envConfig.baseURL.split("/api")[0];
    if (!url) return "";
    const host = url.split("?")[0].split("/")[2];
    const shareUrl = ["development"].includes(process.env.NODE_ENV)
      ? `${baseUrl}/wechat/image?url=${url}&host=${host}`
      : `https://${window.location.hostname}/wechat/image?url=${url}&host=${host}`;
    return shareUrl;
  }
};
if (window) {
  window.tool = tool;
}

export default tool;

<template>
  <div>
    <el-input
      v-model="value"
      v-bind="$attrs"
      :show-password="showPassword"
      :maxlength="maxLength"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      @input="input"
      @blur="blur"
    >
    </el-input>
  </div>
</template>

<script>
import { passwordValidate, passwordValidateTips } from "@/utils/validate";

export default {
  name: "PassWord",
  props: {
    password: {
      type: String,
      default: ""
    },
    maxLength: {
      type: Number,
      default: 16
    },
    showPassword: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "请输入登录密码（8-16位数字或字母）"
    },
    isMessage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      desc: "密码组件",
      value: ""
    };
  },
  watch: {
    password: {
      immediate: true,
      handler: function() {
        this.value = this.password;
      }
    }
  },
  methods: {
    input() {
      this.$emit("input", this.value);
    },
    blur(e) {
      this.passwordValidate(true);
      this.$emit("blur", e);
    },
    passwordValidate(type) {
      const state = passwordValidate(this.value);
      if (!state && type && this.isMessage) {
        this.$message.error(passwordValidateTips);
      }
      return state;
    }
  }
};
</script>

<style scoped></style>

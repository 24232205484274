import request from "@/utils/request";

// 获取创建订单前的参数
export function getBeforeCreateOrderParams(params) {
  return request({
    url: "/mall/orders/order-params",
    method: "post",
    data: params
  });
}

// 创建订单
export function createOrder(params) {
  return request({
    url: "/mall/orders",
    method: "post",
    data: params
  });
}

// 订单列表
export function getOrderList(params) {
  return request({
    url: "/mall/orders/list",
    method: "get",
    params: params
  });
}

// 订单详情
export function getOrderDetail(params) {
  return request({
    url: `/mall/orders/${params.id}`,
    method: "get",
    params: params
  });
}

// 订单日志
export function getOrderLogs(params) {
  return request({
    url: `/mall/orders/logs/${params.id}`,
    method: "get"
  });
}

// 更新订单
export function updateOrders(params) {
  return request({
    url: `/mall/orders/${params.id}`,
    method: "post",
    data: params
  });
}

// 更新订单 by code
export function updateOrdersByCode(params) {
  return request({
    url: `/mall/orders/update`,
    method: "post",
    data: params
  });
}

// 合并支付订单列表
export function getOrderToPayList(params) {
  return request({
    url: "/mall/orders/to-pay",
    method: "get",
    params: params
  });
}

// 获取支付方式
export function getOrdersPayList(params) {
  return request({
    url: `/mall/orders/pay/list`,
    method: "post",
    data: params
  });
}

// 获取商城资料
export function getCompanies() {
  return request({
    url: `/mall/companies`,
    method: "get"
  });
}

// 获取批量支付金额
export function getOrdersPayAmount(params) {
  return request({
    url: `/mall/orders/pay-amount`,
    method: "post",
    data: params
  });
}

//合并付款发起支付
export function postOrdersPay(params) {
  return request({
    url: `/mall/orders/pay`,
    method: "post",
    data: params
  });
}

// 获取ERP物流信息列表
export function getLogisticsErpByCode(params) {
  return request({
    url: `/mall/logistics/erp/${params.orderCode}`,
    method: "get"
  });
}

//订单明细可收货列表
export function postOrdersToReceiveList(params) {
  return request({
    url: `/mall/order-details/to-receive`,
    method: "post",
    data: params
  });
}

//订单批量收货
export function postOrdersBatchReceive(params) {
  return request({
    url: `/mall/orders/batch-receive`,
    method: "post",
    data: params
  });
}

//删除订单
export function postOrdersDelete(params) {
  return request({
    url: `/mall/orders/delete`,
    method: "post",
    data: params
  });
}

//订单列表导出
export function postOrdersExport(params) {
  return request({
    url: `/mall/orders/export`,
    method: "post",
    contentType: "application/json; charset=utf-8",
    responseType: "blob",
    data: params
  });
}

//订单批量取消
export function ordersBatchCancel(params) {
  return request({
    url: `/mall/orders/batch-cancel`,
    method: "post",
    data: params
  });
}

// 订单统计数量
export function getOrdersCounts() {
  return request({
    url: `/mall/orders/counts`,
    method: "get"
  });
}

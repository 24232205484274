import Table from "./src/table";
import Pagination from "./src/pagination";
import EditColumns from "./src/edit-columns";

Table.install = function(Vue) {
  Vue.component(Table.name, Table);
};

Pagination.install = function(Vue) {
  Vue.component(Pagination.name, Pagination);
};

EditColumns.install = function(Vue) {
  Vue.component(EditColumns.name, EditColumns);
};

export { Table, Pagination, EditColumns };

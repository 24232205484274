<template>
  <el-dialog
    title="列设置(按住列标题上下拖动排序)"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="420px"
    top="10vh"
    :close="close"
    append-to-body
    class="edit-columns"
  >
    <div>
      <div class="text-danger m-b-10">
        * 显示列过多，分页数较大时可能导致列表卡顿！
      </div>
      <table border="1" cellpadding="0" cellspacing="0" class="edit-columns-table">
        <thead>
          <tr>
            <th width="40%">
              列标题
            </th>
            <th width="40%">
              列宽
            </th>
            <th width="20%">
              是否显示
            </th>
          </tr>
        </thead>
      </table>
      <div class="edit-columns-tbody">
        <table border="1" cellpadding="0" cellspacing="0" class="edit-columns-table">
          <draggable
            v-model="editList"
            handle=".edit-columns-td-move"
            chosen-class="chosen"
            force-fallback="true"
            group="people"
            animation="300"
            tag="tbody"
          >
            <tr v-for="item in editList" :key="item.name">
              <td width="40%" class="edit-columns-td-move">
                {{ item.label }}
              </td>
              <td width="40%">
                <el-input-number
                  v-model="item.width"
                  :min="minWidth"
                  :max="10000"
                  size="mini"
                  class="width-full"
                ></el-input-number>
              </td>
              <td width="20%">
                <el-switch v-model="item.isShow" :disabled="item.cantHide" size="mini" class="width-full" />
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
    <div slot="footer">
      <el-button size="mini" @click="restoreDefault">恢复默认</el-button>
      <el-button size="mini" type="primary" @click="saveSettings">保存设置(F3)</el-button>
    </div>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable";
let lastKeyScope;
export default {
  name: "EditColumns",
  components: {
    draggable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tableName: {
      type: String,
      required: true
    },
    baseColumns: {
      type: Array,
      required: true
    },
    minWidth: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      editList: [],
      backEndColumns: [],
      keyScope: "editColumns",
      getPropValue: window.tool.getPropValue
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      }
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          // this.changeEditList();
          lastKeyScope = this.$hotkeys.getScope();
          setTimeout(() => {
            this.$hotkeys.setScope(this.keyScope);
          }, 500);
        } else {
          this.$hotkeys.setScope(lastKeyScope);
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.bindKeyboard();
    this.changeEditList();
  },
  destroyed() {
    this.$hotkeys.deleteScope(this.keyScope);
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    bindKeyboard() {
      this.$hotkeys("f3", { scope: this.keyScope }, (e, handler) => {
        switch (handler.key) {
          case "f3":
            e.preventDefault();
            this.saveSettings();
            break;
          default:
            return;
        }
      });
    },
    changeEditList() {
      const columns = this.getStorageColumns();
      if (columns) {
        this.editList = this.baseColumns
          .map(item => {
            // 查找对应的索引
            const index = columns.findIndex(it => it && this.getPropValue(it.prop) === this.getPropValue(item.prop));
            const columnItem = columns[index];
            if (columnItem) {
              return {
                ...item,
                width: columnItem.width,
                isShow: !columnItem.isHide || columnItem.cantHide,
                index
              };
            } else {
              // 没有时
              return {
                ...item,
                isShow: item.isShow !== undefined ? item.isShow : true,
                index: 999
              };
            }
          })
          .sort((a, b) => a.index - b.index);
      } else {
        this.editList = window.tool.deepCloneRecursive(this.baseColumns).map(item => {
          if (item.isShow === undefined) {
            item.isShow = true;
          }
          return item;
        });
      }
      this.$emit("columns-change", this.editList);
    },
    restoreDefault() {
      this.editList = window.tool.deepCloneRecursive(this.baseColumns).map(item => {
        if (item.isShow === undefined) {
          item.isShow = true;
        }
        return item;
      });
    },
    saveSettings() {
      this.setStorageColumns(this.editList);
      const columns = this.editList.filter(item => !item.forcedHide && (item.isShow || item.cantHide));
      this.$emit("columns-change", columns);
      this.close();
    },
    getStorageColumns() {
      if (localStorage[this.tableName + "Columns"]) {
        return JSON.parse(localStorage[this.tableName + "Columns"]);
      }
      return null;
    },
    setStorageColumns(columns) {
      localStorage[this.tableName + "Columns"] = JSON.stringify(columns);
    }
  }
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import { envConfig } from "envConfigPath";
import children from "./children";
Vue.use(VueRouter);

const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(e => e);
};
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(e => e);
};

const routes = [
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/forget-password",
    name: "忘记密码",
    component: () => import("@/views/forgetPassword/index.vue")
  },
  {
    path: "*",
    name: "首页",
    iCons: "icon-client-menu",
    redirect: "/products",
    component: () => import("@/views/index.vue"),
    children: [...children]
  }
];

const router = new VueRouter({
  mode: "history",
  base: envConfig.routerPrefix,
  routes
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
